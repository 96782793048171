import dayjs from 'dayjs';
import { PublicServiceHistoryResponse } from '../../models';
import './History.css';
import { HealthStatusType, getHealthStatusText } from '../../models/PublicService.model';

interface HistoryProps {
  data: PublicServiceHistoryResponse;
}

export function History({ data }: HistoryProps) {
  const formattedData = data.data
    .map(d => {
      return {
        ...d,
        createdOn: dayjs.unix(d.createdOn),
      };
    })
    .sort((a, b) => (a.createdOn.isBefore(b.createdOn) ? 1 : -1))
    .reduce(
      (
        acc: {
          date: string;
          incidents: { healthStatus: HealthStatusType; info?: string; time: string }[];
        }[],
        curr
      ) => {
        const date = curr.createdOn.format('MMM. DD, YYYY');
        const accDate = acc.find(d => d.date === date);
        if (accDate && curr.healthStatus !== 'Healthy') {
          accDate.incidents.push({
            healthStatus: curr.healthStatus,
            time: curr.createdOn.format('hh:mm A'),
            info: curr.info,
          });
        } else {
          const incidents =
            curr.healthStatus === 'Healthy'
              ? []
              : [{ healthStatus: curr.healthStatus, time: curr.createdOn.format('hh:mm A'), info: curr.info }];
          acc.push({ date, incidents });
        }

        return acc;
      },
      []
    );

  return (
    <div className="History__wrapper">
      <h2>Past Incidents</h2>
      <div className="separator-horizontal -lighter" />
      {formattedData.map(d => {
        return (
          <div key={d.date}>
            <h5 className="sub-title black-text semi-bold">{d.date}</h5>
            {d.incidents.length > 0 ? (
              d.incidents.map(i => {
                return (
                  <div key={i.time} className="flex-row">
                    <div className={`md-box box ${i.healthStatus === 'Incident' ? '-yellow' : '-red'}`}></div>
                    <div className="text grey-text">{i.info || getHealthStatusText(i.healthStatus)}</div>{' '}
                    <span className="badge medium xs-text">{i.time}</span>
                  </div>
                );
              })
            ) : (
              <div className="small-text grey-text">No incidents reported</div>
            )}
            <div className="separator-horizontal -lighter" />
          </div>
        );
      })}
    </div>
  );
}
