import { useState } from 'react';
import logo from '../../assets/logo.svg';
import MailOutlined from '@ant-design/icons/lib/icons/MailOutlined';
import { Button, Dropdown } from 'antd';
import './STHeader.css';
import { MenuOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

export function STHeader() {
  const [contactUsVisible, setContactUsVisible] = useState<boolean>(false);

  const items = [
    {
      key: '1',
      label: (
        <Link to="/about" target="_blank">
          Go to Official Website
        </Link>
      ),
    },
    {
      key: '2',
      label: <a href="mailto: info@statustrust.com">Contact Us</a>,
    },
  ];

  return (
    <header className="STHeader__wrapper flex-row plr-page pt-lg">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <Dropdown className="hoverable desktop-hidden" menu={{ items }} placement="bottomLeft">
        <MenuOutlined style={{ fontSize: '28px', color: '#1e1e1e' }} />
      </Dropdown>
      <Button onClick={() => window.open('/about', '_blank')} className="mobile-hidden" size="large">
        Go to Official Website
      </Button>
      <Button
        onClick={() => setContactUsVisible(!contactUsVisible)}
        className="mobile-hidden"
        type="primary"
        size="large"
      >
        Contact Us
      </Button>
      {contactUsVisible && (
        <a href="mailto: info@statustrust.com" className="flex-row link">
          <MailOutlined style={{ color: '#7752fe' }} />
          info@statustrust.com
        </a>
      )}
    </header>
  );
}
