import { PublicService, PublicServiceChart, PublicServiceHistoryResponse, PublicServiceResponse } from '../models';
import { api } from './configs/axiosConfigs';

export const PublicServiceAPI = {
  getPaginated: async function ({
    page = 0,
    pageSize = 6,
    name,
    category,
  }: {
    page?: number;
    pageSize?: number;
    name?: string;
    category?: string;
  }): Promise<PublicServiceResponse> {
    const response = await api.request<PublicServiceResponse>({
      url: '/public/services',
      method: 'GET',
      params: {
        page,
        pageSize,
        name,
        category,
      },
    });

    return response.data;
  },

  getAll: async function ({ category, name }: { category?: string; name?: string }): Promise<PublicService[]> {
    const allData: PublicService[] = [];
    let currentPage = 0;

    const loadPage = async (): Promise<PublicService[]> => {
      return this.getPaginated({ page: currentPage, category, name }).then(data => {
        allData.push(...data.data);
        currentPage++;
        if (data.totalPageCount > currentPage) {
          return loadPage();
        }

        return Promise.resolve(allData);
      });
    };

    return loadPage();
  },

  getById: async function (serviceId: string): Promise<PublicService> {
    const response = await api.request<PublicServiceResponse>({
      url: '/public/services',
      method: 'GET',
      params: {
        page: 0,
        pageSize: 1,
        serviceId,
      },
    });

    return response.data.data[0];
  },

  getChart: async function (serviceId: string): Promise<PublicServiceChart> {
    const response = await api.request<PublicServiceChart>({
      url: `/public/${serviceId}/chart`,
      method: 'GET',
    });
    return response.data;
  },

  getHistory: async function (serviceId: string): Promise<PublicServiceHistoryResponse> {
    const response = await api.request<PublicServiceHistoryResponse>({
      url: `/public/${serviceId}/history`,
      method: 'GET',
      params: {
        page: 0,
        pageSize: 150,
      },
    });
    return response.data;
  },
};
