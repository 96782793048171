import { PublicServiceEvent } from '../models';
import { fetchEventSource } from '../utils';

export const PublicServiceEventAPI = {
  subscribe: async function (): Promise<PublicServiceEvent> {
    return new Promise(resolve => {
      fetchEventSource(process.env.REACT_APP_API_URL! + '/events', {
        method: 'GET',
        headers: {
          accept: 'text/event-stream',
        },
        onmessage(msg) {
          try {
            const result = JSON.parse(msg.data);
            resolve(result);
            throw new Error('Restart sse');
          } catch (e) {
            throw new Error(msg.data);
          }
        },
        onerror(err) {
          console.error(err);
        },
      });
    });
  },
};
