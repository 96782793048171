import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import './index.css';
import './styles/icon.css';
import './styles/layout.css';
import './styles/ant-overrides.css';
import PublicSearch from './pages/PublicSearch';
import { STLayout } from './pages/STLayout';
import { ServiceDetail } from './pages/ServiceDetail';
import { stStore } from './stStore';
import { Provider } from 'react-redux';
import { Website } from './pages/Website';

const router = createBrowserRouter([
  {
    path: '',
    element: <STLayout />,
    children: [
      {
        path: '',
        element: <PublicSearch />,
      },
      {
        path: ':id',
        element: <ServiceDetail />,
      },
    ],
  },
  {
    path: 'about',
    element: <Website />,
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ConfigProvider
    theme={{
      token: { fontFamily: 'Outfit', colorPrimary: '#7752fe' },
      components: {
        Modal: {
          borderRadiusLG: 30,
        },
        Select: {
          fontSizeIcon: 16,
          fontSize: 17,
        },
        Input: {
          fontSize: 17,
        },
        Button: {
          fontSize: 20,
          paddingContentHorizontal: 20,
          fontWeight: 500,
          borderRadius: 5,
          controlHeight: 42,
          colorBgContainerDisabled: '#ffffff',
          fontSizeLG: 17,
          paddingInlineLG: 20,
          controlHeightLG: 45,
          defaultBg: 'transparent',
          defaultColor: '#7752fe',
          defaultBorderColor: '#7752fe',
        },
        Dropdown: {
          fontSize: 17,
        },
      },
    }}
  >
    <React.StrictMode>
      <Provider store={stStore}>
        <RouterProvider router={router} />
      </Provider>
    </React.StrictMode>
  </ConfigProvider>
);
