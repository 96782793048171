import { useEffect } from 'react';
import { SearchForm } from '../components/form';
import { Flex, Spin, message } from 'antd';
import { StatusGroup } from '../components/ui';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { STState } from '../stStore';
import { fetchCategories } from '../features/categoriesSlice';
import { setFilter } from '../features/filterSlice';
import { toggleFavorite } from '../features/favoritesSlice';
import { initServices, loadMore, filterServices, updateService } from '../features/servicesSlice';
import { useSTDispatch } from '../stStore';
import { PublicServiceEventAPI } from '../apis';

function PublicSearch() {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const categories = useSelector((state: STState) => state.categories);
  const favorites = useSelector((state: STState) => state.favorites);
  const services = useSelector((state: STState) => state.services);
  const filter = useSelector((state: STState) => state.filter);
  const dispatch = useSTDispatch();

  useEffect(() => {
    if (categories.loading === 'idle') {
      dispatch(fetchCategories())
        .unwrap()
        .then(res => {
          dispatch(
            initServices({
              categories: res.map(c => c.name),
              favorites: favorites.values.map(f => f.id),
            })
          );
        })
        .catch((err: any) => {
          messageApi.open({
            type: 'error',
            content: err?.message,
          });
        });
    }
  }, [dispatch, categories, messageApi, favorites.values]);

  useEffect(() => {
    PublicServiceEventAPI.subscribe().then(payload => {
      dispatch(updateService(payload));
    });
  }, [dispatch]);

  const handleFormSubmit = ({ name, category }: { name?: string; category?: string }) => {
    dispatch(setFilter({ category, name }));
    if (name?.length || category?.length) {
      dispatch(filterServices({ name, category }));
    } else {
      dispatch(
        initServices({
          categories: categories.values.map(c => c.name),
          favorites: favorites.values.map(f => f.id),
        })
      );
    }
  };

  return (
    <>
      {contextHolder}
      <div className="Search__wrapper">
        <div className="plr-page pt-lg">
          <div className="compact">
            <h1 className="accent-900-text">
              Keep Track. <br />
              Keep Growing.
            </h1>
            <h4>
              Status Trust empowers companies to effortlessly monitor and optimize the status of their operations for
              enhanced efficiency and success.
            </h4>
          </div>
          {categories.loading === 'succeeded' && (
            <SearchForm
              onSubmit={(name, category) => handleFormSubmit({ name, category })}
              categories={categories.values}
              loading={services.loading === 'loading'}
            />
          )}
        </div>
        <div>
          {categories.loading === 'loading' || services.loadingCategory === 'all' ? (
            <Flex justify="center">
              <Spin size="large" />
            </Flex>
          ) : (
            services.values.map(([category, serviceResponse], index) => {
              return (
                serviceResponse.data.length > 0 && (
                  <StatusGroup
                    onClickFavorite={(id: string, category: string) => {
                      dispatch(toggleFavorite({ id, category }));
                    }}
                    onClickServiceStatus={(id: string) => navigate(id)}
                    onClickLoadMore={() => dispatch(loadMore({ category, filter }))}
                    key={category}
                    title={category}
                    favorites={favorites.values.map(f => f.id)}
                    serviceList={serviceResponse.data}
                    hasMore={serviceResponse.page < serviceResponse.totalPageCount - 1}
                    loadMoreVisible={category !== 'Favorites'}
                    bgColor={index % 2 === 0 ? 'transparent' : '#f5f5f5'}
                    isLoading={services.loadingCategory === category}
                  />
                )
              );
            })
          )}
        </div>
      </div>
    </>
  );
}

export default PublicSearch;
