import './Website.css';
import logo from '../assets/logo-light.svg';
import arrow from '../assets/icons/arrow-circled.svg';

export function Website() {
  return (
    <div className="Website">
      <div className="page flex-col">
        <div className="header flex-row">
          <div>
            <img alt="logo" src={logo}></img>
          </div>
          <div className="placeholder"></div>
          <div className="label">COMING SOON</div>
        </div>

        <div className="content">
          <h1>Keep track.</h1>
          <h1>Keep growing.</h1>
        </div>

        <div className="footer flex-row">
          <div>
            <a href="mailto:info@statustrust.com" target="_blank" rel="noreferrer">
              <img alt="arrow" src={arrow} />
            </a>
          </div>
          <div>
            <p>
              <b>Status Trust</b> empowers companies to effortlessly monitor and optimize the status of their operations
              for enhanced efficiency and success.
            </p>
          </div>
        </div>
      </div>

      <div className="background">
        <div className="gradient height20"></div>
        <div className="gradient height40"></div>
        <div className="gradient height60"></div>
        <div className="gradient height80"></div>
      </div>
    </div>
  );
}
