export type HealthStatusType = 'Incident' | 'Healthy' | 'Outage';

export interface PublicServiceEvent {
  healthInfo: string;
  healthStatus: HealthStatusType;
  lastStatusCheck: number;
  serviceId: string;
}

export interface PublicService {
  serviceId: string;
  clientId?: string;
  name: string;
  healthy: boolean;
  healthStatus: HealthStatusType;
  healthInfo?: string;
  category: string;
  logoUrl?: string;
  lastStatusCheck?: number;
}

export interface PublicServiceResponse {
  page: number;
  pageSize: number;
  totalCount: number;
  totalPageCount: number;
  data: PublicService[];
}

export interface PublicServiceChart {
  data: {
    date: string;
    totalCheckCount: number;
    totalIncidentCount: number;
    totalSuccessCount: number;
  }[];
  summary: {
    reportPercentage: number;
    totalCheckCount: number;
    totalFailureCount: number;
    totalIncidentCount: number;
    totalSuccessCount: number;
  };
}

export interface PublicServiceHistory {
  createdOn: number;
  healthStatus: HealthStatusType;
  info: string;
  responseTime: string;
}

export interface PublicServiceHistoryResponse {
  data: PublicServiceHistory[];
  page: number;
  pageSize: number;
  totalCount: number;
  totalPageCount: number;
}

export const getHealthStatusColor = (status: HealthStatusType) => {
  const map: Record<HealthStatusType, string> = {
    Incident: '#ffa500',
    Healthy: '#50c878',
    Outage: '#ff5733',
  };

  return map[status];
};

export const getHealthStatusText = (status: HealthStatusType) => {
  const map: Record<HealthStatusType, string> = {
    Incident: 'Incident',
    Healthy: 'Operational',
    Outage: 'Downtime',
  };

  return map[status];
};
